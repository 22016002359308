import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ImageElement, modalNotification, Input as TextInput } from '../../../components';
import adminRouteMap from '../../../routes/Admin/adminRouteMap';
import { validateConfirmPassword } from "../../../utils/validation";
import { patch } from "../../../utils/apiHelpers";


function ResetPassword() {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const validationErrors = validateConfirmPassword(password, confirmPassword);
      if (validationErrors) {
        modalNotification({ type: 'error', message: validationErrors });
        return;
      }

      const payload = {
        password
      };

      const res = await patch(`/users/reset-password?token=${token}`, payload);
      if (res.data.success) {
        modalNotification({ type: 'success', message: res?.data?.message });

        navigate(adminRouteMap.LOGIN.path);
      }
    } catch (error) {
      console.error('Error in generating reset password link:', error);
      modalNotification({ type: 'error', message: error?.response?.data?.message || error?.response?.data?.error });
    }
  };

  return (
    <>
      <div className="authPage d-flex align-items-center justify-content-center">
        <Container>
          <div className="authPage_wrapper">
            <div className="authPage_logo mb-sm-5 mb-4 text-center">
              <Link to="/" className="logo-link">
                <ImageElement className="img-fluid" source="logo.svg" style={{ width: '200px' }} alt="logo" />
              </Link>
            </div>
            <div className="authPage_box my-3">
              <Row className="g-0 align-items-center">
                <Col lg={6}>
                  {/* <div className="h-100 authPage_box_bg align-items-end"></div> */}
                  <div className="authPage_box_bg d-none d-lg-block">
                    <ImageElement source="login-left.png" className="img-fluid w-100" alt="auth-left-img" />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="px-xl-5 p-sm-4 p-3">
                    <div className="authPage_form">
                      <div className="authPage_form_head text-center">
                        <h2 className="font-size-18">Reset Password</h2>
                        <p className="fs-16 text-muted mb-0">Please create new password</p>
                      </div>
                      <form onSubmit={handleSubmit} className="auth-input">
                        <div className="form-group form-control-wrap">
                          <label className="form-label form-label-floating" htmlFor="password-input">New Password</label>
                          <TextInput
                            name="password"
                            type={showPassword ? "text" : "password"}
                            className="form-control form-control-lg"
                            placeholder="Enter password"
                            icon={
                              <Link to="#" className="fieldicon-right" onClick={(e) => { e.preventDefault(); setShowPassword(!showPassword) }}>
                                {showPassword ? <em className="icon-eye-off" /> : <em className="icon-eye" />}
                              </Link>}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                          />

                        </div>
                        <div className="form-group form-control-wrap">
                          <label className="form-label form-label-floating" htmlFor="password-input">Confirm New Password</label>
                          <TextInput
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control form-control-lg"
                            placeholder="Enter password"
                            icon={
                              <Link to="#" className="fieldicon-right" onClick={(e) => { e.preventDefault(); setShowConfirmPassword(!showConfirmPassword) }}>
                                {showConfirmPassword ? <em className="icon-eye-off" /> : <em className="icon-eye" />}
                              </Link>}
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mt-4">
                          <Button className="btn btn-lg btn-primary w-100" type="submit">Reset Password</Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mt-sm-5 mt-3 text-center">
              <p className="fs-16 text-muted mb-0">© 2024 qahwah.</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;