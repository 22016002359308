import React, { useState, useRef } from 'react';
import { Input, InputNumber } from 'antd';
import { Select } from '../../Antd';
import modalNotification from '../../Toster';
import { post, put } from '../../../utils/apiHelpers';
import { uploadFile } from '../../../utils/fileUpload';
import { Link, useNavigate } from 'react-router-dom';
import adminRouteMap from '../../../routes/Admin/adminRouteMap';

const Product = ({
    productID,
    name: existingName,
    format: existingFormat,
    flavour: existingFlavour,
    brandName: existingBrandName,
    weight: existingWeight,
    origin: existingOrigin,
    roastProfile: existingRoastProfile,
    strength: existingStrength,
    bestFor: existingBestFor,
    suitableFor: existingSuitableFor,
    description: existingDescription,
    productType: existingProductType,
    status: existingStatus,
    price: existingPrice,
    discountPrice: existingDiscountPrice,
    availableStock: existingAvailableStock,
    coverURL,
    signedURL: existingSignedURL
}) => {
    const [name, setName] = useState(existingName || '');
    const [format, setFormat] = useState(existingFormat || '');
    const [flavour, setFlavour] = useState(existingFlavour || '');
    const [brandName, setBrandName] = useState(existingBrandName || '');
    const [weight, setWeight] = useState(existingWeight || '');
    const [origin, setOrigin] = useState(existingOrigin || '');
    const [roastProfile, setRoastProfile] = useState(existingRoastProfile || '');
    const [strength, setStrength] = useState(existingStrength || '');
    const [bestFor, setBestFor] = useState(existingBestFor || '');
    const [suitableFor, setSuitableFor] = useState(existingSuitableFor || '');
    const [description, setDescription] = useState(existingDescription || '');
    const [productType, setProductType] = useState(existingProductType || '');
    const [status, setStatus] = useState(existingStatus || 0);
    const [price, setPrice] = useState(existingPrice || 0);
    const [discountPrice, setDiscountPrice] = useState(existingDiscountPrice || 0);
    const [availableStock, setAvailableStock] = useState(existingAvailableStock || 0);
    const [signedURL, setSignedURL] = useState(existingSignedURL || '');
    const [coverImage, setCoverImage] = useState(null);
    const [pending, setPending] = useState(false);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();



    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCoverImage(file);
            const localURL = URL.createObjectURL(file);
            setSignedURL(localURL);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setPending(true);
            if (coverImage) {
                coverURL = await uploadFile(coverImage);
            }

            const payload = {
                name,
                format,
                flavour,
                brandName,
                weight,
                origin,
                roastProfile,
                strength,
                bestFor,
                suitableFor,
                description,
                productType,
                status,
                price,
                discountPrice,
                availableStock,
                coverURL
            };

            let res;
            if (productID) {
                res = await put(`/products/${productID}`, payload);
            } else {
                res = await post("/products", payload);
            }

            if (res.data.success) {
                modalNotification({ type: 'success', message: res?.data?.message });
                !productID && navigate(adminRouteMap.PRODUCTS.path);
            }
        } catch (error) {
            console.error('Error in adding/updating product:', error);
            modalNotification({ type: 'error', message: error?.response?.data?.message || error?.response?.data?.error });
        } finally {
            setPending(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='form-group'>
                <label className='form-label'>Name</label>
                <Input
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='Enter Product Name'
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Description</label>
                <Input
                    name='description'
                    type='text'
                    className='form-control'
                    placeholder='Enter Product Description'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Format</label>
                <Input
                    name='format'
                    type='text'
                    className='form-control'
                    placeholder='Enter Format'
                    value={format}
                    onChange={e => setFormat(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Flavour</label>
                <Input
                    name='flavour'
                    type='text'
                    className='form-control'
                    placeholder='Enter Flavour'
                    value={flavour}
                    onChange={e => setFlavour(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Brand Name</label>
                <Input
                    name='brandName'
                    type='text'
                    className='form-control'
                    placeholder='Enter Brand Name'
                    value={brandName}
                    onChange={e => setBrandName(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Weight</label>
                <Input
                    name='weight'
                    type='text'
                    className='form-control'
                    placeholder='Enter Weight'
                    value={weight}
                    onChange={e => setWeight(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Origin</label>
                <Input
                    name='origin'
                    type='text'
                    className='form-control'
                    placeholder='Enter Origin'
                    value={origin}
                    onChange={e => setOrigin(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Roast Profile</label>
                <Input
                    name='roastProfile'
                    type='text'
                    className='form-control'
                    placeholder='Enter Roast Profile'
                    value={roastProfile}
                    onChange={e => setRoastProfile(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Strength</label>
                <Input
                    name='strength'
                    type='text'
                    className='form-control'
                    placeholder='Enter Strength'
                    value={strength}
                    onChange={e => setStrength(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Best For</label>
                <Input
                    name='bestFor'
                    type='text'
                    className='form-control'
                    placeholder='Enter Best For'
                    value={bestFor}
                    onChange={e => setBestFor(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Suitable For</label>
                <Input
                    name='suitableFor'
                    type='text'
                    className='form-control'
                    placeholder='Enter Suitable For'
                    value={suitableFor}
                    onChange={e => setSuitableFor(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Product Type</label>
                <Input
                    name='productType'
                    type='text'
                    className='form-control'
                    placeholder='Enter Product Type'
                    value={productType}
                    onChange={e => setProductType(e.target.value)}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Status</label>
                <div className="form-control-select">
                    <Select
                        arrayOfData={[
                            { id: 0, name: "Inactive" },
                            { id: 1, name: "Active" }
                        ]}
                        value={status}
                        onSelectChange={value => setStatus(value)}
                        required
                    />
                </div>
            </div>
            <div className='form-group'>
                <label className='form-label'>Price</label>
                <InputNumber
                    name='price'
                    className='form-control'
                    placeholder='Enter Price'
                    value={price}
                    onChange={value => setPrice(value)}
                    min={0}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Discount Price</label>
                <InputNumber
                    name='discountPrice'
                    className='form-control'
                    placeholder='Enter Discount Price'
                    value={discountPrice}
                    onChange={value => setDiscountPrice(value)}
                    min={0}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Available Stock</label>
                <InputNumber
                    name='availableStock'
                    className='form-control'
                    placeholder='Enter Available Stock'
                    value={availableStock}
                    onChange={value => setAvailableStock(value)}
                    min={0}
                    required
                />
            </div>
            <div className='form-group'>
                <label className='form-label'>Cover Image</label>
                {signedURL && (
                    <div className="mt-4">
                        <img
                            src={signedURL}
                            alt="Cover"
                            style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
                        />
                    </div>
                )}

                {!signedURL && coverURL && (
                    <div className="mt-4 p-3 bg-light border rounded">
                        <p>An image URL is set, but no preview is available. The image may be loading or unavailable.</p>
                    </div>
                )}
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                <div className="d-flex align-items-center mt-4">
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => fileInputRef.current.click()}
                    >
                        Choose File
                    </button>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
                <Link to={adminRouteMap.PRODUCTS.path}>
                    <button className='btn btn-md btn-light me-4'>Cancel</button>
                </Link>
                <button className='btn btn-primary' type='submit' disabled={pending}>
                    {pending ? "Saving..." : (productID ? "Update Product" : "Add Product")}
                </button>
            </div>
        </form>
    );
};

export default Product;