
const adminRouteMap = {
  LOGIN: { path: `/` },
  DASHBOARD: { path: `/dashboard` },
  FORGOTPASSWORD: { path: `/forgot-password` },
  RESETPASSWORD: { path: `/reset-password` },
  USERS: { path: `/users` },
  USERDETAILS: { path: `/user-details` },
  EDITUSER: { path: `/edit-user` },
  BLOGS: { path: `/blogs` },
  BLOGDETAILS: { path: `/blog-details` },
  ADDBLOG: { path: `/add-blog` },
  EDITBLOG: { path: `/edit-blog` },
  PRODUCTS: { path: `/products` },
  PRODUCTDETAILS: { path: `/product-details` },
  ADDPRODUCT: { path: `/add-product` },
  EDITPRODUCT: { path: `/edit-product` },
  SELLER: { path: `/seller` },
  ACTIVEORDERS: { path: `/active-orders` },
  COMPLETEDORDERS: { path: `/completed-orders` },
  CANCELLEDORDERS: { path: `/cancelled-orders` },
  USERDETAILS: { path: `/user-details` },
  FORMELEMENT: { path: `/form-element` },
  PRIVACYPOLICY: { path: `/privacy-policy` },
  TERMSANDCONDITIONS: { path: `/terms-and-condition` },
};
export default adminRouteMap;