import React, { useEffect } from "react";
import Swal from "sweetalert2";

function SweetAlert({ show, onConfirmAlert, setIsAlertVisible, ...rest }) {
  useEffect(() => {
    if (show) {
      Swal.fire({
        ...rest,
        preConfirm: async () => {
          await onConfirmAlert();
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setIsAlertVisible(false);
        } else if (result.isDismissed) {
          setIsAlertVisible(false);
        }
      });
    }
  }, [show]);

  return null;
}
export default SweetAlert;
