import adminRouteMap from "./adminRouteMap";
import { ActiveOrders, AdminDashboard, AddBlog, EditBlog, Blogs, BlogDetails, AddProduct, EditProduct, Products, ProductDetails, CancelledOrders, CompleteOrders, Users, FormElement, PrivacyPolicy, Seller, TermsConditions, UsreDetails } from "../../pages";
import EditUser from "../../pages/Admin/Users/EditUser/index.page";


export default function route() {
  return [
    {
      path: adminRouteMap.DASHBOARD.path,
      name: "Dashboard",
      element: <AdminDashboard />,
    },
    {
      path: adminRouteMap.USERS.path,
      name: "Users",
      element: <Users />,
    },
    {
      path: adminRouteMap.BLOGS.path,
      name: "Blogs",
      element: <Blogs />,
    },
    {
      path: `${adminRouteMap.BLOGDETAILS.path}/:blogID`,
      name: "Blog Details",
      element: <BlogDetails />,
    },
    {
      path: adminRouteMap.ADDBLOG.path,
      name: "Add Blog",
      element: <AddBlog />,
    },
    {
      path: `${adminRouteMap.EDITBLOG.path}/:blogID`,
      name: "Edit Blog",
      element: <EditBlog />,
    },
    {
      path: adminRouteMap.PRODUCTS.path,
      name: "Products",
      element: <Products />,
    },
    {
      path: `${adminRouteMap.PRODUCTDETAILS.path}/:productID`,
      name: "Product Details",
      element: <ProductDetails />,
    },
    {
      path: adminRouteMap.ADDPRODUCT.path,
      name: "Add Product",
      element: <AddProduct />,
    },
    {
      path: `${adminRouteMap.EDITPRODUCT.path}/:productID`,
      name: "Edit Product",
      element: <EditProduct />,
    },
    {
      path: adminRouteMap.SELLER.path,
      name: "Users",
      element: <Seller />,
    },
    {
      path: `${adminRouteMap.USERDETAILS.path}/:userID`,
      name: "User Details",
      element: <UsreDetails />,
    },
    {
      path: `${adminRouteMap.EDITUSER.path}/:userID`,
      name: "Edit User ",
      element: <EditUser />,
    },
    {
      path: adminRouteMap.ACTIVEORDERS.path,
      name: "Active Orders",
      element: <ActiveOrders />,
    },
    {
      path: adminRouteMap.COMPLETEDORDERS.path,
      name: "Completed Orders",
      element: <CompleteOrders />,
    },
    {
      path: adminRouteMap.CANCELLEDORDERS.path,
      name: "Cancelled Orders",
      element: <CancelledOrders />,
    },

    {
      path: adminRouteMap.FORMELEMENT.path,
      name: "Form Element",
      element: <FormElement />,
    },
    {
      path: adminRouteMap.PRIVACYPOLICY.path,
      name: "Privacy Policy",
      element: <PrivacyPolicy />,
    },
    {
      path: adminRouteMap.TERMSANDCONDITIONS.path,
      name: "Terms and COnditions",
      element: <TermsConditions />,
    },
  ];
}
